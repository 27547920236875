<template>
  <page-wrapper title="Подтверждение почтового ящика" />
</template>

<script>
import { mapActions } from 'vuex'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import ApiFactory from '@/api/ApiFactory'
import { AUTH_READY } from '@/store/actions/auth'

const UsersApi = ApiFactory.get('users')

export default {
  name: 'EmailConfirmation',
  components: { PageWrapper },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        const { ['email-confirm']: emailConfirm } = route.query
        if (emailConfirm) this.confirmEmail(emailConfirm)
        else this.$router.push('/profile')
      }
    }
  },
  methods: {
    ...mapActions({
      getProfile: 'profile/getProfile',
      authReady: `auth/${AUTH_READY}`
    }),
    confirmEmail(emailConfirm) {
      UsersApi.confirmEmail({ code: emailConfirm })
        .then(({ data }) => {
          data.redirect = true
          if (!this.isAuth && data.token) this.authReady(data)
          else {
            this.getProfile()
            this.$router.push('/profile')
          }
        })
        .catch(err => {
          this.errHandler(err)
          this.$router.push('/profile')
        })
    }
  }
}
</script>
